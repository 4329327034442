






































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
interface InfoData {
  // eslint-disable-next-line camelcase
  publishUserId_richObj: string;
  classificationLayer: string;
  address: string;
  publishTime: string;
  content: string;
}
@Component
export default class feedbackMore extends Vue {
  @PropSync('dialogVisible', { type: Boolean, default: false })
  visible!: boolean;

  @Prop({
    type: Object,
    default: () => {
      return {}
    }
  })
  infoData!: InfoData;
}
